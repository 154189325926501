import React , {useState,useEffect} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import "./cssClasses/custom.css"
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import MobileMenu from './layout/MobileMenu';
import SideMenu from './layout/SideMenu';
import TopBar from './layout/TopBar';
import GeneralReport from './layout/GeneralReport';
import { loginApi, postBearerToken } from '../utils/Apis';
import { useNavigate } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const HotelList = () => {

    const navigate = useNavigate()
    React.useEffect(() => {


        const LoadExternalScript = () => {
            const externalScript = document.createElement("script");
            // externalScript.onerror = loadError;
            externalScript.id = "external";
            externalScript.async = true;
            externalScript.type = "text/javascript";
            externalScript.setAttribute("crossorigin", "anonymous");
            document.body.appendChild(externalScript);
            externalScript.src = '../dist/js/app.js';
        };
        LoadExternalScript();


        return () => {

            // document.body.removeChild(externalScript);
        };
    }, []);
    const getRowId = (row) => row._id; // Replace 'customId' with your own unique identifier field

    const [rows, setrows] = React.useState([])

    React.useEffect(() => {
        // fetch_data()
    }, [])

    const [travel_date, settravel_date] = useState(null)
    const [from_date, setfrom_date] = useState(null)
    const [to_date, setto_date] = useState(null)
    const [phone, setphone] = useState("")
    const [email, setemail] = useState("")
    const [api, setapi] = useState("")
    const [booking_id, set_booking_id] = useState("")
    const [pnr, set_pnr] = useState("")


    const fetch_data = async () => {

        let json ={}

        if(travel_date){
            json["travel_date"] = travel_date
        }
        if(from_date){
            json["from_date"] = from_date
        }
        if(to_date){
            json["to_date"] = to_date
        }
        if(phone){
            json["phone"] = phone
        }
        if(email){
            json["email"] = email
        }
        if(email){
            json["email"] = email
        }
        if(api){
            json["api"] = api
        }
        if(booking_id){
            json["booking_id"] = booking_id
        }

        loginApi("filter_booking_hotels" , json).then((result) => {
            if (result.error_code == 0) {
                console.log(result.data)
                setrows(result.data)
            } else {
                // notify(JSON.stringify(result.messages))
            }
        })

    }


    const columns = [
        
        {
            field: '_id',
            headerName: 'RefId',
            headerClassName: "grid-header",
            flex: 1,
            editable: true,
        },
        {
            field: 'response_data_api',
            headerName: 'API',
            headerClassName: "grid-header",
            flex: 1,
        },
        {
            field: 'booking_id',
            headerName: 'BOOKING ID',
            headerClassName: "grid-header",
            flex: 1,
        },
        {
            field: 'primary_phone',
            headerClassName: "grid-header",
            headerName: 'PHONE',
            flex: 1,
        },
        {
            field: "primary_email",
            headerClassName: "grid-header",
            headerName: "EMAIL",
            flex: 1,
            editable: false,

        },
        {
            field: "created_at",
            headerClassName: "grid-header",
            headerName: "Booking Date",
            flex: 1,
            editable: false,
            renderCell : (params) => {
                return  <>
                <label>
                    {params.row.created_at?.split("T")[0]}
                </label>
                </>
            }


        },
        {
            field: "action",
            headerName: "Details",
            flex: 1,
            headerClassName: "grid-header",
            renderCell : (params) => {
                return <button className="btn btn-primary btn-sm"
                onClick={() => {
                    navigate("/hotel-booking-details?_id="+params.row._id , {state : { booking_id  :params.row.booking_id  , api : params.row.response_data_api}} )
                }}
                >Details</button>
            }
        }

    ];


    const [isSideMenuOpen, setIsSideMenuOpen] = React.useState(false);
    const handleOpen = () => {
        setIsSideMenuOpen(!isSideMenuOpen);
    };

    const handleClose = () => {
        setIsSideMenuOpen(!isSideMenuOpen);
    };
    return (
        <div className="py-5 md:py-0">
            {/* <!-- BEGIN: Mobile Menu --> */}
            <div
                className={`mobile-menu md:hidden ${isSideMenuOpen ? "mobile-menu--active" : ""
                    }`}
            >
                <MobileMenu handleOpen={handleOpen} handleClose={handleClose} />

            </div>
            {/* <!-- END: Mobile Menu -->
        <!-- BEGIN: Top Bar --> */}
            <TopBar />
            {/* <!-- END: Top Bar --> */}
            <div className={`overflow-hidden ${isSideMenuOpen ? "" : "flex"}`}>
                {/* <!-- BEGIN: Side Menu --> */}
                <SideMenu className={`side-nav ${isSideMenuOpen ? "open" : ""}`} />
                {/* <!-- END: Side Menu -->
            <!-- BEGIN: Content --> */}
                <div className="content">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 2xl:col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                {/* <!-- BEGIN: General Report --> */}
                                <div className="col-span-12 mt-6">
                                    <div class="grid grid-cols-12 gap-6 mt-5">
                                        <div class="intro-y col-span-12 lg:col-span-4">
                                            <div class="intro-y box p-5">
                                                <div>
                                                    <label style={{textAlign:"left" , display:"flex"}}>CheckIn/Out Date</label>

                                                    <input
                                                        id="crud-form-1"
                                                        value={travel_date}
                                                        onChange={(e) => settravel_date(e.target.value)}
                                                        type="date"
                                                        class="form-control w-full"
                                                        placeholder="From Date (Travel Date)"
                                                    />
                                                </div>
                                                

                                                <div style={{marginTop:10}}>
                                                    <label style={{textAlign:"left" , display:"flex"}}>From Date (Booking Date)</label>

                                                    <input
                                                        id="crud-form-1"
                                                        value={from_date}
                                                        onChange={(e) => setfrom_date(e.target.value)}
                                                        type="date"
                                                        class="form-control w-full"
                                                        placeholder="From Date (Booking Date)"
                                                    />
                                                </div>
                                                <div style={{marginTop:10}}>
                                                    <label style={{textAlign:"left" , display:"flex"}}>To Date (Booking Date)</label>
                                                    <input
                                                        id="crud-form-1"
                                                        value={to_date}
                                                        onChange={(e) => setto_date(e.target.value)}
                                                        type="date"
                                                        class="form-control w-full"
                                                        placeholder="To Date (Booking Date)"
                                                    />
                                                </div>

                                                
                                            </div>
                                        </div>
                                        <div class="intro-y col-span-12 lg:col-span-4">
                                            <div class="intro-y box p-5">
                                                <div>
                                                    <input
                                                        id="crud-form-1"
                                                        value={email}
                                                        onChange={(e) => setemail(e.target.value)}
                                                        type="text"
                                                        class="form-control w-full"
                                                        placeholder="Email"
                                                    />
                                                </div>
                                                <div style={{marginTop:10}}>
                                                    <input
                                                        id="crud-form-1"
                                                        value={phone}
                                                        onChange={(e) => setphone(e.target.value)}
                                                        type="text"
                                                        class="form-control w-full"
                                                        placeholder="Phone"
                                                    />
                                                </div>

                                                <div style={{marginTop:10}}>
                                                    <input
                                                        id="crud-form-1"
                                                        value={booking_id}
                                                        onChange={(e) => set_booking_id(e.target.value)}
                                                        type="text"
                                                        class="form-control w-full"
                                                        placeholder="Booking Id"
                                                    />
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="intro-y col-span-12 lg:col-span-4">
                                            <div class="intro-y box p-5">
                                                <div>
                                                    <select className='form-control'
                                                    value={api}
                                                    onChange={(e) => setapi(e.target.value)}
                                                    >
                                                        <option value={"TRIPJACK"}>TRIPJACK</option>
                                                        <option value={"TBO"}>TBO</option>
                                                    </select>
                                                </div>
                                                <div style={{marginTop:10 }}>
                                                    
                                                    <button onClick={()=>fetch_data()} className='btn btn-primary'>Search</button>
                                                </div>

                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-12 mt-6">
                                    <div className="intro-y block sm:flex items-center h-10">
                                        <h2 className="text-lg font-medium truncate mr-5">
                                            Hotel Booking
                                        </h2>
                                        <div className="flex items-center sm:ml-auto mt-3 sm:mt-0">
                                            <button className="btn box flex items-center text-slate-600 dark:text-slate-300"> <i data-lucide="file-text" className="hidden sm:block w-4 h-4 mr-2"></i> Export to Excel </button>
                                            <button className="ml-3 btn box flex items-center text-slate-600 dark:text-slate-300"> <i data-lucide="file-text" className="hidden sm:block w-4 h-4 mr-2"></i> Export to PDF </button>
                                        </div>
                                    </div>
                                    <div className="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
                                        <DataGrid
                                            sx={{ mt: 1 }}
                                            rows={rows?rows:[]}
                                            columns={columns}
                                            showCellVerticalBorder
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 10,
                                                    },
                                                },
                                            }}
                                            pageSizeOptions={[10,20,40,100]}
                                            getRowId={getRowId}

                                        />
                                    </div>

                                </div>
                                {/* <!-- END: Weekly Top Products --> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- END: Content --> */}
            </div>


        </div>
    )
}

export default HotelList