import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { GlobalContext } from "../../navigation/ContextProvider";

const MobileMenu = (props) => {
  const search = useLocation().pathname;
  const navigate = useNavigate();
  const {
    isGlobalAccess,
    setGlobalAccess,
    isGlobalAccessType,
    setGlobalAccessType,
  } = React.useContext(GlobalContext);
  // console.log(isGlobalAccessType);

  return (
    <>
      <div className="mobile-menu-bar">
        <a href="" className="flex mr-auto">
          <img
            alt="Midone - HTML Admin Template"
            className="w-6"
            src="dist/images/logo.svg"
          />
        </a>
        <a
          href="javascript:;"
          className="mobile-menu-toggler"
          onClick={props.handleOpen}
        >
          {" "}
          <MenuIcon className="w-8 h-8 text-white transform -rotate-90" />{" "}
        </a>
      </div>
      <div className="scrollable">
        <a
          href="javascript:;"
          className="mobile-menu-toggler"
          onClick={props.handleClose}
        >
          {" "}
          <HighlightOffIcon />{" "}
        </a>
        <ul className="scrollable__content py-2">
          {/* <li>
            <a href="javascript:;.html" className="menu menu--active">
              <div className="menu__icon">
                {" "}
                <i data-lucide="home"></i>{" "}
              </div>
              <div className="menu__title">
                {" "}
                Dashboard{" "}
                <i
                  data-lucide="chevron-down"
                  className="menu__sub-icon transform rotate-180"
                ></i>{" "}
              </div>
            </a>
          </li> */}

          <li>
            <a
              href="/admin-punch-jobs"
              className={
                search == "/admin-punch-jobs"
                  ? "side-menu  side-menu--active"
                  : "side-menu"
              }
            >
              <div className="side-menu__icon">
                {" "}
                <i data-lucide="box"></i>{" "}
              </div>
              <div className="side-menu__title">Assign Jobs</div>
            </a>
          </li>
          <li>
            <a
              href="/admin-dispatcher-supervisor"
              className={
                search == "/admin-dispatcher-supervisor"
                  ? "side-menu  side-menu--active"
                  : "side-menu"
              }
            >
              <div className="side-menu__icon">
                {" "}
                <i data-lucide="box"></i>{" "}
              </div>
              <div className="side-menu__title">Supervisor</div>
            </a>
          </li>
          {isGlobalAccessType == "Admin" ? (
            <>
              <li>
                <a
                  href="/admin-user-foreman"
                  className={
                    search == "/admin-user-foreman"
                      ? "side-menu  side-menu--active"
                      : "side-menu"
                  }
                >
                  <div className="side-menu__icon">
                    {" "}
                    <i data-lucide="box"></i>{" "}
                  </div>
                  <div className="side-menu__title">Foreman</div>
                </a>
              </li>
              <li>
                <a
                  href="/admin-user-dispatcher"
                  className={
                    search == "/admin-user-dispatcher"
                      ? "side-menu  side-menu--active"
                      : "side-menu"
                  }
                >
                  <div className="side-menu__icon">
                    {" "}
                    <i data-lucide="box"></i>{" "}
                  </div>
                  <div className="side-menu__title">Dispatcher</div>
                </a>
              </li>
              <li>
                <a
                  href="/admin-jobs"
                  className={
                    search == "/admin-jobs"
                      ? "side-menu  side-menu--active"
                      : "side-menu"
                  }
                >
                  <div className="side-menu__icon">
                    {" "}
                    <i data-lucide="box"></i>{" "}
                  </div>
                  <div className="side-menu__title">Jobs</div>
                </a>
              </li>
              <li>
                <a
                  href="/admin-material"
                  className={
                    search == "/admin-material"
                      ? "side-menu  side-menu--active"
                      : "side-menu"
                  }
                >
                  <div className="side-menu__icon">
                    {" "}
                    <i data-lucide="box"></i>{" "}
                  </div>
                  <div className="side-menu__title">Materials</div>
                </a>
              </li>
              <li>
                <a
                  href="/admin-trucktype"
                  className={
                    search == "/admin-trucktype"
                      ? "side-menu  side-menu--active"
                      : "side-menu"
                  }
                >
                  <div className="side-menu__icon">
                    {" "}
                    <i data-lucide="box"></i>{" "}
                  </div>
                  <div className="side-menu__title">Truck Type</div>
                </a>
              </li>

              <li>
                <a
                  href="/admin-add-company"
                  className={
                    search == "/admin-add-company"
                      ? "side-menu  side-menu--active"
                      : "side-menu"
                  }
                >
                  <div className="side-menu__icon">
                    {" "}
                    <i data-lucide="users"></i>{" "}
                  </div>
                  <div className="side-menu__title">Companies</div>
                </a>
              </li>
            </>
          ) : null}
          <li>
            <div
              className={"side-menu"}
              onClick={() => {
                localStorage.clear();
                navigate("/login");
              }}
            >
              <div className="side-menu__icon">
                {" "}
                <i data-lucide="users"></i>{" "}
              </div>
              <div className="side-menu__title ">Logout</div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MobileMenu;
