import React from 'react'

const DetailFlightTbo = (props) => {
    return (
        <div class="content" style={{ width: "100vw" }}>
            <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
                <h2 class="text-lg font-medium mr-auto">
                    Transaction Details
                </h2>
                <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
                    <button class="btn btn-primary shadow-md mr-2">Print</button>
                    <div class="dropdown ml-auto sm:ml-0">
                        <button class="dropdown-toggle btn px-2 box" aria-expanded="false" data-tw-toggle="dropdown">
                            <span class="w-5 h-5 flex items-center justify-center"> <i class="w-4 h-4" data-lucide="plus"></i> </span>
                        </button>
                        <div class="dropdown-menu w-40">
                            <ul class="dropdown-content">
                                <li>
                                    <a href="" class="dropdown-item"> <i data-lucide="file" class="w-4 h-4 mr-2"></i> Export Word </a>
                                </li>
                                <li>
                                    <a href="" class="dropdown-item"> <i data-lucide="file" class="w-4 h-4 mr-2"></i> Export PDF </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="intro-y grid grid-cols-11 gap-5 mt-5">
                <div class="col-span-12 lg:col-span-7 2xl:col-span-12">
                    <div class="box p-5 rounded-md">
                        <div class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5 mb-5">
                            <div class="font-medium text-base truncate">{"Passenger Info"}</div>
                            <a href="" class="flex items-center ml-auto text-primary"> <i data-lucide="plus" class="w-4 h-4 mr-2"></i> Add Notes </a>
                        </div>
                        <div class="overflow-auto lg:overflow-visible -mt-3">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th class="whitespace-nowrap !py-5">PNR</th>
                                        <th class="whitespace-nowrap text-right">NAME</th>
                                        <th class="whitespace-nowrap text-right">DOB</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        props.details.Response.FlightItinerary.Passenger && props.details.Response.FlightItinerary.Passenger.length > 0
                                            ?
                                            props.details.Response.FlightItinerary.Passenger.map((item, index) => (
                                                <tr>
                                                    <td class="!py-4">
                                                        <div class="flex items-center">
                                                            <div class="w-10 h-10 image-fit zoom-in">
                                                                <img alt="Midone - HTML Admin Template" class="rounded-lg border-2 border-white shadow-md tooltip" src="dist/images/preview-2.jpg" title="Uploaded at 9 October 2020" />
                                                            </div>
                                                            <a href="" class="font-medium whitespace-nowrap ml-4">{item.Ticket.TicketNumber}</a>
                                                        </div>
                                                    </td>
                                                    <td class="text-right">{item.Title} {item.FirstName} {item.LastName}</td>
                                                    <td class="text-right">{item.DateOfBirth}</td>
                                                </tr>

                                            ))
                                            :
                                            null
                                    }






                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="intro-y grid grid-cols-11 gap-5 mt-5">
                <div class="col-span-12 lg:col-span-4 2xl:col-span-3">
                    <div class="box p-5 rounded-md">
                        <div class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5 mb-5">
                            <div class="font-medium text-base truncate">Transaction Details</div>
                            <a href="" class="flex items-center ml-auto text-primary"> <i data-lucide="edit" class="w-4 h-4 mr-2"></i> Change Status </a>
                        </div>
                        <div class="flex items-center"> <i data-lucide="clipboard" class="w-4 h-4 text-slate-500 mr-2"></i> Booking ID : <a href="" class="underline decoration-dotted ml-1">{props.details.Response.FlightItinerary.BookingId}</a> </div>
                        <div class="flex items-center mt-3"> <i data-lucide="calendar" class="w-4 h-4 text-slate-500 mr-2"></i> Booking Date: {props.details.Response.FlightItinerary.InvoiceCreatedOn.split("T")[0]} {props.details.Response.FlightItinerary.InvoiceCreatedOn.split("T")[1]} </div>
                        {/* <div class="flex items-center mt-3"> <i data-lucide="clock" class="w-4 h-4 text-slate-500 mr-2"></i> Transaction Status: <span class="bg-success/20 text-success rounded px-2 ml-1">{props.details.order.status}</span> </div> */}
                    </div>
                    <div class="box p-5 rounded-md mt-5">
                        <div class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5 mb-5">
                            <div class="font-medium text-base truncate">Primary Details</div>
                            <a href="" class="flex items-center ml-auto text-primary"> <i data-lucide="edit" class="w-4 h-4 mr-2"></i> View Details </a>
                        </div>
                        {/* <div class="flex items-center"> <i data-lucide="clipboard" class="w-4 h-4 text-slate-500 mr-2"></i> Name: <a href="" class="underline decoration-dotted ml-1">{"Robert De Niro"}</a> </div> */}
                        <div class="flex items-center mt-3"> <i data-lucide="calendar" class="w-4 h-4 text-slate-500 mr-2"></i> Phone Number: {props.details.Response.FlightItinerary.Passenger[0].ContactNo} </div>
                        <div class="flex items-center mt-3"> <i data-lucide="map-pin" class="w-4 h-4 text-slate-500 mr-2"></i> Email: {props.details.Response.FlightItinerary.Passenger[0].Email} </div>
                    </div>
                    <div class="box p-5 rounded-md mt-5">
                        <div class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5 mb-5">
                            <div class="font-medium text-base truncate">Payment Details</div>
                        </div>
                        <div class="flex items-center">
                            <i data-lucide="clipboard" class="w-4 h-4 text-slate-500 mr-2"></i> Payment Method:
                            <div class="ml-auto">Direct bank transfer</div>
                        </div>
                        <div class="flex items-center mt-3">
                            <i data-lucide="credit-card" class="w-4 h-4 text-slate-500 mr-2"></i> Total Price :
                            <div class="ml-auto">Rs. {props.details.Response.FlightItinerary.InvoiceAmount}</div>
                        </div>
                        <div class="flex items-center mt-3">
                            <i data-lucide="credit-card" class="w-4 h-4 text-slate-500 mr-2"></i> Convinence Fee:
                            <div class="ml-auto">Rs. {10}</div>
                        </div>

                        <div class="flex items-center border-t border-slate-200/60 dark:border-darkmode-400 pt-5 mt-5 font-medium">
                            <i data-lucide="credit-card" class="w-4 h-4 text-slate-500 mr-2"></i> Grand Total:
                            <div class="ml-auto">Rs. {props.details.Response.FlightItinerary.InvoiceAmount + 10}</div>
                        </div>
                    </div>
                    {/* <div class="box p-5 rounded-md mt-5">
                        <div class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5 mb-5">
                            <div class="font-medium text-base truncate">Misc Information</div>
                            <a href="" class="flex items-center ml-auto text-primary"> <i data-lucide="map-pin" class="w-4 h-4 mr-2"></i> Tracking Info </a>
                        </div>
                        <div class="flex items-center"> <i data-lucide="clipboard" class="w-4 h-4 text-slate-500 mr-2"></i> Courier: Left4code Express </div>
                        <div class="flex items-center mt-3"> <i data-lucide="calendar" class="w-4 h-4 text-slate-500 mr-2"></i> Tracking Number: 003005580322 <i data-lucide="copy" class="w-4 h-4 text-slate-500 ml-2"></i> </div>
                        <div class="flex items-center mt-3"> <i data-lucide="map-pin" class="w-4 h-4 text-slate-500 mr-2"></i> Address: 260 W. Storm Street New York, NY 10025. </div>
                    </div> */}
                </div>
                <div class="col-span-12 lg:col-span-7 2xl:col-span-8">
                    <div class="box p-5 rounded-md">
                        <div class="flex items-center border-b border-slate-200/60 dark:border-darkmode-400 pb-5 mb-5">
                            <div class="font-medium text-base truncate">{"Segments"}</div>
                            <a href="" class="flex items-center ml-auto text-primary"> <i data-lucide="plus" class="w-4 h-4 mr-2"></i> Add Notes </a>
                        </div>
                        <div class="overflow-auto lg:overflow-visible -mt-3">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th class="whitespace-nowrap !py-5">Name</th>
                                        <th class="whitespace-nowrap text-right">Departure</th>
                                        <th class="whitespace-nowrap text-right">Departure Terminal</th>
                                        <th class="whitespace-nowrap text-right">Arrival</th>
                                        <th class="whitespace-nowrap text-right">Arrival Terminal</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        props.details.Response.FlightItinerary.Segments && props.details.Response.FlightItinerary.Segments.length > 0
                                            ?
                                            props.details.Response.FlightItinerary.Segments.map((item, index) => (
                                                <>
                                                    <hr style={{ height: 50 }}>
                                                    </hr>


                                                    <tr>
                                                        <td class="!py-4">
                                                            <div class="flex items-center">
                                                                <div class="w-10 h-10 image-fit zoom-in">
                                                                    <img alt="Midone - HTML Admin Template" class="rounded-lg border-2 border-white shadow-md tooltip" src="dist/images/preview-2.jpg" title="Uploaded at 9 October 2020" />
                                                                </div>
                                                                <a href="" class="font-medium whitespace-nowrap ml-4">{item.Airline.AirlineName}-{item.Airline.FlightNumber}</a>
                                                            </div>
                                                        </td>
                                                        <td class="text-right">{item.Origin.Airport.AirportCode}<br></br>{item.Origin.Airport.AirportName}</td>
                                                        <td class="text-right">{item.Origin.Airport.Terminal}<br></br>{item.Origin.DepTime.split("T")[0]} {item.Origin.DepTime.split("T")[1]}</td>
                                                        <td class="text-right">{item.Destination.Airport.AirportCode}<br></br>{item.Destination.Airport.AirportName}</td>
                                                        <td class="text-right">{item.Destination.Airport.Terminal}<br></br>{item.Destination.ArrTime.split("T")[0]} {item.Destination.ArrTime.split("T")[1]}</td>
                                                        
                                                    </tr>


                                                </>

                                            ))
                                            :
                                            null
                                    }






                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailFlightTbo