import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import "./cssClasses/custom.css"
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import MobileMenu from './layout/MobileMenu';
import SideMenu from './layout/SideMenu';
import TopBar from './layout/TopBar';
import GeneralReport from './layout/GeneralReport';
import { useLocation, useSearchParams } from 'react-router-dom';
import { loginApi } from '../utils/Apis';
import './cssClasses/hotelDetail.css'; // Import your CSS file
import DetailHotelTripJack from './layout/DetailHotelTripJack';
import DetailFligthTripJack from './layout/DetiailFlightTripJack';
import DetailFlightTbo from './layout/DetailFlightTbo';

const DetailsFlights = (props) => {


    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search)
    const _id = queryParameters.get("_id")

    // const dataReceived = location.state;
    const [details, setdetails] = React.useState(null)
    const [api, setapi] = React.useState("")
    // console.log(dataReceived ,_id )

    React.useEffect(() => {


        const LoadExternalScript = () => {
            const externalScript = document.createElement("script");
            // externalScript.onerror = loadError;
            externalScript.id = "external";
            externalScript.async = true;
            externalScript.type = "text/javascript";
            externalScript.setAttribute("crossorigin", "anonymous");
            document.body.appendChild(externalScript);
            externalScript.src = '../dist/js/app.js';
        };
        LoadExternalScript();


        return () => {

            // document.body.removeChild(externalScript);
        };
    }, []);

    const [rows, setrows] = React.useState([])

    React.useEffect(() => {
        fetch_details()
    }, [])


    const fetch_details = async () => {


        loginApi("filter_booking_flights_details", {
            // api: dataReceived?.api,
            // booking_id: dataReceived?.booking_id,
            _id: _id
        }).then((result) => {
            if (result.error_code == 0) {
                console.log(result.data)
                setdetails(result.data)
                setapi(result.api)
                // setrows(result.data)
            } else {
                // notify(JSON.stringify(result.messages))
            }
        })

    }


    const columns = [
        {
            field: 'id', headerName: 'ID',
            flex: 1,

            headerClassName: "grid-header",
        },
        {
            field: 'name',
            headerName: 'Name',
            headerClassName: "grid-header",
            flex: 1,
            editable: true,
        },
        {
            field: 'email',
            headerName: 'Email',
            headerClassName: "grid-header",
            flex: 1,
            editable: true,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            headerClassName: "grid-header",
            type: 'number',
            flex: 1,
            editable: true,
        },
        {
            field: 'username',
            headerClassName: "grid-header",
            headerName: 'Username',
            flex: 1,
        },
        {
            field: 'user_type',
            headerClassName: "grid-header",
            headerName: 'User Type',
            flex: 1,
        },
        {
            field: "status",
            headerClassName: "grid-header",
            headerName: "Status",
            flex: 1,

            renderCell: (params) => (

                <div class="flex items-center justify-center text-danger"> <CheckBoxOutlinedIcon class="w-4 h-4 mr-2" sx={{ color: "#B91c1c", }} />  Inactive </div>

            )
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            headerClassName: "grid-header",

            renderCell: (params) => (

                <div class="flex justify-center items-center">
                    <a class="flex items-center mr-3" href=""> <EditIcon class="w-4 h-4 mr-1" />  Edit </a>
                    <a class="flex items-center text-danger" href="">  <DeleteOutlineRoundedIcon class="w-4 h-4 mr-1" sx={{ color: "#B91c1c", }} /> Delete </a>
                </div>

            )
        }

    ];


    const [isSideMenuOpen, setIsSideMenuOpen] = React.useState(false);
    const handleOpen = () => {
        setIsSideMenuOpen(!isSideMenuOpen);
    };

    const handleClose = () => {
        setIsSideMenuOpen(!isSideMenuOpen);
    };
    return (
        <div className="py-5 md:py-0">
            {/* <div
                className={`mobile-menu md:hidden ${isSideMenuOpen ? "mobile-menu--active" : ""
                    }`}
            >
                <MobileMenu handleOpen={handleOpen} handleClose={handleClose} />

            </div> */}
            {/* <TopBar /> */}
            <div className={`overflow-hidden ${isSideMenuOpen ? "" : "flex"}`}>
                {/* <SideMenu className={`side-nav ${isSideMenuOpen ? "open" : ""}`} /> */}
                <div className='row'>
                    {
                        api!="" && api=="TRIPJACK" && details
                            ?
                            <DetailFligthTripJack details={details}/>
                            :
                            null
                    }
                    {
                        api!="" && api=="TBO" && details
                            ?
                            <DetailFlightTbo details={details}/>
                            :
                            null
                    }
                </div>
                {/* <!-- END: Content --> */}
            </div>


        </div>
    )
}

export default DetailsFlights