import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import "./cssClasses/custom.css"
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import MobileMenu from './layout/MobileMenu';
import SideMenu from './layout/SideMenu';
import TopBar from './layout/TopBar';
import GeneralReport from './layout/GeneralReport';
const Reports = () => {
    React.useEffect(() => {


        const LoadExternalScript = () => {
            const externalScript = document.createElement("script");
            // externalScript.onerror = loadError;
            externalScript.id = "external";
            externalScript.async = true;
            externalScript.type = "text/javascript";
            externalScript.setAttribute("crossorigin", "anonymous");
            document.body.appendChild(externalScript);
            externalScript.src = '../dist/js/app.js';
        };
        LoadExternalScript();


        return () => {

            // document.body.removeChild(externalScript);
        };
    }, []);

    const [rows, setrows] = React.useState([])

    React.useEffect(() => {
        // fetch_users()
    }, [])


    // const fetch_users = async () => {


    //     getBearerToken("fetch_users").then((result) => {
    //         if (result.error == 0) {
    //             console.log(result.data)
    //             setrows(result.data)
    //         } else {
    //             // notify(JSON.stringify(result.messages))
    //         }
    //     })

    // }


    const columns = [
        {
            field: 'id', headerName: 'ID',
            flex: 1,

            headerClassName: "grid-header",
        },
        {
            field: 'name',
            headerName: 'Name',
            headerClassName: "grid-header",
            flex: 1,
            editable: true,
        },
        {
            field: 'email',
            headerName: 'Email',
            headerClassName: "grid-header",
            flex: 1,
            editable: true,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            headerClassName: "grid-header",
            type: 'number',
            flex: 1,
            editable: true,
        },
        {
            field: 'username',
            headerClassName: "grid-header",
            headerName: 'Username',
            flex: 1,
        },
        {
            field: 'user_type',
            headerClassName: "grid-header",
            headerName: 'User Type',
            flex: 1,
        },
        {
            field: "status",
            headerClassName: "grid-header",
            headerName: "Status",
            flex: 1,

            renderCell: (params) => (

                <div class="flex items-center justify-center text-danger"> <CheckBoxOutlinedIcon class="w-4 h-4 mr-2" sx={{ color: "#B91c1c", }} />  Inactive </div>

            )
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            headerClassName: "grid-header",

            renderCell: (params) => (

                <div class="flex justify-center items-center">
                    <a class="flex items-center mr-3" href=""> <EditIcon class="w-4 h-4 mr-1" />  Edit </a>
                    <a class="flex items-center text-danger" href="">  <DeleteOutlineRoundedIcon class="w-4 h-4 mr-1" sx={{ color: "#B91c1c", }} /> Delete </a>
                </div>

            )
        }

    ];


    const [isSideMenuOpen, setIsSideMenuOpen] = React.useState(false);
    const handleOpen = () => {
        setIsSideMenuOpen(!isSideMenuOpen);
    };

    const handleClose = () => {
        setIsSideMenuOpen(!isSideMenuOpen);
    };
    return (
        <div className="py-5 md:py-0">
            {/* <!-- BEGIN: Mobile Menu --> */}
            <div
                className={`mobile-menu md:hidden ${isSideMenuOpen ? "mobile-menu--active" : ""
                    }`}
            >
                <MobileMenu handleOpen={handleOpen} handleClose={handleClose} />

            </div>
            {/* <!-- END: Mobile Menu -->
        <!-- BEGIN: Top Bar --> */}
            <TopBar />
            {/* <!-- END: Top Bar --> */}
            <div className={`overflow-hidden ${isSideMenuOpen ? "" : "flex"}`}>
                {/* <!-- BEGIN: Side Menu --> */}
                <SideMenu className={`side-nav ${isSideMenuOpen ? "open" : ""}`} />
                {/* <!-- END: Side Menu -->
            <!-- BEGIN: Content --> */}
                <div className="content">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 2xl:col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                {/* <!-- BEGIN: General Report --> */}
                                <GeneralReport />

                                <div className="col-span-12 mt-6">
                                    <div className="intro-y block sm:flex items-center h-10">
                                        <h2 className="text-lg font-medium truncate mr-5">
                                            Job Assigments
                                        </h2>
                                        <div className="flex items-center sm:ml-auto mt-3 sm:mt-0">
                                            <button className="btn box flex items-center text-slate-600 dark:text-slate-300"> <i data-lucide="file-text" className="hidden sm:block w-4 h-4 mr-2"></i> Export to Excel </button>
                                            <button className="ml-3 btn box flex items-center text-slate-600 dark:text-slate-300"> <i data-lucide="file-text" className="hidden sm:block w-4 h-4 mr-2"></i> Export to PDF </button>
                                        </div>
                                    </div>
                                    <div className="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
                                        {/* <DataGrid
                                            sx={{ mt: 1 }}
                                            rows={rows}
                                            columns={columns}
                                            showCellVerticalBorder
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 5,
                                                    },
                                                },
                                            }}
                                            pageSizeOptions={[9]}
                                        /> */}
                                    </div>

                                </div>
                                {/* <!-- END: Weekly Top Products --> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- END: Content --> */}
            </div>


        </div>
    )
}

export default Reports